import React from "react";
import { Link } from "react-router-dom";

const SuccessContainer = () => {
  return (
    <div className="res-cont flex justify-center items-center">
      <div className="md:w-[60%]">
        <h1 className=" font-bold text-3xl text-center">
          You are now officially on the
          <span className="text-primary">“waitlist”</span>
        </h1>
        <p className="text-center py-10 mb-4 font-medium text-md">
          Don’t take your eyes off your device while we update you on our
          launch. Stay tuned!
        </p>
        <div className="h-[80vh] flex justify-center">
          <div>
            {/* Button */}
            <Link to={"/"} className="primary-btn">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessContainer;
