import React from "react";
import { Link } from "react-router-dom";
import { whiteBg } from "../../constants/import";
import { socialData } from "../../constants/data";
const Footer = () => {
  const genericLink = "text-xs leading-6";
  return (
    <div className="w-full res-cont text-white justify-between bg-primary">
      <div className="w-full md:flex grid grid-cols-1 justify-between items-start border-b border-white ">
        {/* Firtst Column  */}
        <div className="md:w-[40%]">
          <img src={whiteBg} alt="whiteBg" className="mb-4" />
          <p className="basic-info text-justify">
            There's someone out there searching for you. We're here to help you
            find each other and fall in love.
          </p>
          {/* Social Links */}
          <div className="flex my-4">
            {socialData.map((item, index) => (
              <a
                href={item.link}
                target="_blank"
                key={index}
                className={`${index === 0 ? "" : ""} mr-3 p-2`}
              >
                <item.Icon size={18} color="white" />
              </a>
            ))}
          </div>
        </div>
        {/* Second Column */}
        <div className="md:w-[30%] mb-4 flex justify-between items-start md:block">
          <div className="">
            <h1 className="font-semibold text-md md:text-center mb-2">
              Support
            </h1>
            <div className="flex md:justify-center md:items-center">
              <ul className="">
                <li style={{ cursor: "pointer" }} className={genericLink}>
                  <Link to={"/waitlist"}>WaitList</Link>
                </li>
                <li style={{ cursor: "pointer" }} className={genericLink}>
                  <Link to={"/"}>Contact us</Link>
                </li>
                <li style={{ cursor: "pointer" }} className={genericLink}>
                  <Link to={"/"}>Faq</Link>
                </li>
              </ul>
            </div>
          </div>
          <img src={whiteBg} className="block md:hidden h-[100px] w-[60px]" />
        </div>
        <div className="md:w-[30%] hidden md:flex items-start justify-center">
          <img src={whiteBg} className="h-[150px] w-[80px]" />
        </div>
      </div>
      <p className="pt-5 font-light text-center">
        © All Rights Reserved. 2023, Ife.
      </p>
    </div>
  );
};

export default Footer;
