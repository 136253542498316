import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
const socialData = [
  { Icon: FaFacebook, link: "https://www.facebook.com/ifedate" },
  // { Icon: FaTwitter, link: "" },
  { Icon: FaInstagram, link: "https://www.instagram.com/ife_date/" },
  { Icon: FaTiktok, link: "https://www.tiktok.com/@ifedate" },
  // { Icon: FaWhatsapp, link: "" },
  // { Icon: FaYoutube, link: "" },
  // { Icon: FaLinkedinIn, link: "" },
];

export { socialData };
