import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import { countries } from "../../constants/countries";
import { socialData } from "../../constants/data";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import axios from "axios";

const FormContainer = ({ setOnShowSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    location: "",
    emailNotification: false,
  });
  const { fullName, email, phoneNumber, location, emailNotification } =
    formData;
  const onChange = (e) => {
    const { id, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const inputTitle = "text-gray-800 text-sm font-medium mb-4";
  const handleCheckboxChange = () => {
    setFormData((prev) => ({
      ...prev,
      emailNotification: !emailNotification,
    }));
  };
  const isEmailValid = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = () => {
    // e.preventDefault();
    if (
      fullName === "" ||
      email === "" ||
      phoneNumber === "" ||
      location === ""
    ) {
      toast.error("Please all Fields Required");
    } else if (!isEmailValid(email)) {
      toast.error("Please use a valid Email Address");
    } else {
      setLoading(true);
      axios
        .post(
          "https://ife-dating-backend.onrender.com/api/waitlist",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          toast.success("Thank You for Joining our Waitlist");
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            location: "",
            emailNotification: false,
          });
          setLoading(false);
          setOnShowSuccess(true);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error submitting the form:", error);
          toast.error(error.message);
        });
    }
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className="res-cont flex items-center justify-center">
        <div className="md:w-[70%]">
          <h1 className=" md:px-12 px-10 font-medium text-lg md:font-semibold md:text-xl text-center">
            All good things comes to those who{" "}
            <span className="text-primary">“wait”</span>
          </h1>
          <p className="text-center py-5 font-medium text-md">
            Join Waitlist today!
          </p>
          <div className="bg-white  drop-shadow-xl py-10 md:px-14 px-2 rounded-lg mb-14">
            {/* <form> */}
            {/*Full Name */}
            <div className="p-2">
              <label className={inputTitle}>Full Name</label>
              <input
                onChange={onChange}
                value={fullName}
                type="text"
                id="fullName"
                className="text-sm bg-white appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {/* Email Address */}
            <div className="p-2">
              <label className={inputTitle}>Email Address</label>
              <input
                onChange={onChange}
                value={email}
                type="email"
                id="email"
                className="text-sm bg-white appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {/* Phone Number */}
            <div className="p-2">
              <label className={inputTitle}>Phone Number</label>
              <input
                onChange={onChange}
                value={phoneNumber}
                type="text"
                id="phoneNumber"
                className="text-sm bg-white appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {/* Countries */}
            <div className="p-3">
              <label className={inputTitle}>Location</label>
              <select
                onChange={onChange}
                value={location}
                id="location"
                className="text-sm bg-white appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option selected value="">
                  Choose Location
                </option>
                {countries.map((opts, index) => (
                  <option key={index} value={opts.name}>
                    {opts.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="p-3">
              <div
                className="flex items-center cursor-pointer"
                onClick={handleCheckboxChange}
              >
                {emailNotification ? (
                  <MdCheckBox color="#FF3838" size={25} />
                ) : (
                  <MdOutlineCheckBoxOutlineBlank color="gray" size={25} />
                )}
                <label className={`text-xs text-black font-medium ml-2`}>
                  Yes I will like to receive emails with news from Ife
                </label>
              </div>
            </div>
            {/* Bottom */}
            <div className="w-full my-4 block md:flex justify-between items-center">
              <div className="w-full md:block flex justify-center items-center mb-5 md:mb-0">
                <button
                  className="w-full md:w-[180px] text-center text-sm bg-primary text-white font-medium py-3 px-10 rounded-full"
                  type="button"
                  onClick={handleSubmit}
                >
                  Join Waitlist
                </button>
              </div>
              {/* Social Links */}
              <div className="w-full md:block flex justify-center items-center mb-5 md:mb-0">
                <div className="flex my-4">
                  {socialData.map((item, index) => (
                    <a
                      href={item.link}
                      target="_blank"
                      key={index}
                      className={`${
                        index === 0 ? "" : ""
                      } mr-2 bg-black rounded-full p-2`}
                    >
                      <item.Icon size={12} color="white" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainer;
