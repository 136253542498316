import React from "react";
import { heroBg, mobileBg } from "../../constants/import";

const Hero = () => {
  return (
    <div className="hero-home w-full md:py-24 py-8">
      {/* Destop */}
      <img src={heroBg} className="hidden md:block w-full" />
      {/* Mobile */}
      <img src={mobileBg} className="md:hidden block w-full" />
    </div>
  );
};

export default Hero;
