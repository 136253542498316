import React, { useState } from "react";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";

const FAQItem = ({ item, index }) => {
  const [onShowDetails, setOnShowDetails] = useState(false);
  return (
    <div className="w-full bg-white rounded-sm shadow-lg my-5 py-6 md:px-14 px-4">
      <div
        className="flex justify-between items-center w-full"
        onClick={() => setOnShowDetails(!onShowDetails)}
      >
        <h1 className="font-medium md:text-md text-sm">
          {index + 1}
          {".  "}
          {"  "}
          {item.title}
        </h1>
        {/* Icon */}
        <div>
          {onShowDetails ? (
            <FaMinus size={18} className="text-primary" />
          ) : (
            <FaPlus size={18} className="text-primary" />
          )}
        </div>
      </div>
      {onShowDetails && (
        <p className="basic-info font-medium mt-4">{item.desc}</p>
      )}
    </div>
  );
};

export default FAQItem;
