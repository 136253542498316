import React, { useState, useEffect } from "react";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { NavBar } from "../components";
import { Footer } from "../components/Layouts";
const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black text-xs">{children}</td>
);

const WaitListings = () => {
  const [data, setData] = useState([]);
  const [hasAccess, setHasAccess] = useState(false);
  const [pin, setPin] = useState("");

  useEffect(() => {
    // Fetch data from your PHP endpoint
    axios
      .get("https://ife-dating-backend.onrender.com/api/waitlist")
      .then((response) => {
        setData(response.data?.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  function onAcess() {
    if (pin === "2023") {
      setHasAccess(true);
      toast.success("Access Granted");
    } else {
      toast.error("Invalid Access Pin");
    }
  }
  // Function to export data to CSV
  const exportData = () => {
    // Implement CSV export logic here
    const csvData = data.map((item) => ({
      fullName: item.fullName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      location: item.location,
    }));

    return csvData;
  };
  return (
    <div className="w-full">
      <div className="w-full">
        <NavBar />
        {hasAccess ? (
          <div className="py-10 px-5">
            <div className="flex justify-between text-black items-start text-xl pt-1">
              Registered users {`(${data?.length})`}
            </div>

            <div className="bg-white p-6">
              <div
                className={`max-h-[70vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
              >
                {data?.length === 0 ? (
                  <div className=" m-12  justify-items-center justify-center">
                    {" "}
                    <PulseLoader
                      className=" m-12 justify-center"
                      color={"#46A576"}
                      loading={true}
                      size={14}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <div>
                    <CSVLink
                      data={exportData()}
                      filename={"data.csv"}
                      className="bg-primary hover-bg-green-700 text-white font-bold py-5 px-4 rounded mb-4"
                    >
                      Export to CSV
                    </CSVLink>
                    <table
                      className={` w-full  text-sm text-left dark:text-gray-400`}
                    >
                      <thead className="text-xs sticky top-0 right-0 left-0 text-white uppercase bg-primary">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Phone Number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Country
                          </th>
                          {/* <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Date
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className=" pt-8">
                        {data?.length &&
                          data?.map((item, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "bg-orange-100" : "bg-white"
                              }
                            >
                              <TableData>{item.fullName}</TableData>
                              <TableData>{item.email}</TableData>
                              <TableData>{item.phoneNumber}</TableData>
                              <TableData>{item.location}</TableData>
                              {/* <TableData>{item.createdAt}</TableData> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-[600px] flex-row justify-center items-center">
            <center>
              <div className="w-[50%] py-40">
                <h1 className="text-xl text-black">
                  Please Enter Pin to authorize access
                </h1>
                <form>
                  <div className="my-3">
                    <label class="block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Access Pin
                      </span>
                      <input
                        type="text"
                        class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                        placeholder="Access Pin"
                        onChange={(e) => setPin(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className=" bg-primary font-semibold rounded-lg py-2 justify-center w-full text-white flex items-center"
                    onClick={onAcess}
                    type="button"
                  >
                    {"Get Access"}
                  </button>
                </form>
              </div>
            </center>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};
export default WaitListings;
