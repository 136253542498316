import React from "react";
import { workshop } from "../../constants/import";

const WorkShop = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-24 res-cont">
      {/* Image */}
      <div className="justify-end items-end">
        <div className="hidden md:block">
          <img src={workshop} alt="Ife Workshop" className="img-cont" />
        </div>
      </div>
      {/* Description */}
      <div className="">
        <h1 className="heading-text">Ife Workshop</h1>
        <h1 className="font-semibold md:text-[28px] text-lg my-4 leading-9">
          A love handyman that can find the right someone for you{" "}
        </h1>
        <p className="text-sm leading-7">
          Our Ife workshop researchers, behavioural analysts, and matchmakers
          study daters and compatibility so we can make Ife better for you. Cut
          through the noise with the help of our powerful, human centric
          algorithm that understands your preferences and helps you identify
          potential matches who fit them.
        </p>
      </div>
      {/* Image on Mobile */}
      <div className="md:hidden mt-10 w-full flex justify-center items-center">
        <img src={workshop} alt="Ife Workshop" className="img-cont" />
      </div>
    </div>
  );
};

export default WorkShop;
