import React, { useState } from "react";
import { NavBar } from "../components";
import { Footer } from "../components/Layouts";
import { FormContainer, SuccessContainer } from "../components/WaitList";

const JoinWaitList = () => {
  const [onShowSuccess, setOnShowSuccess] = useState(false);
  return (
    <div>
      {/* NavBar */}
      <NavBar />
      <div className="waitbody">
        {onShowSuccess ? (
          <SuccessContainer />
        ) : (
          <FormContainer setOnShowSuccess={setOnShowSuccess} />
        )}
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default JoinWaitList;
