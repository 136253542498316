import React from "react";

const HeroHeader = () => {
  return (
    <div className="px-10 md:px-28">
      <h1 className="md:text-3xl text-xl font-semibold text-center md:leading-10 mt-10 mb-4">
        Your favourite dating app, <br />
        swipe right for the right <span className="text-primary">“choice”</span>
      </h1>
      <p className="text-center text-sm font-medium my-4 md:my-10">
        There's someone out there searching for you. <br />
        We're here to help you find each other and fall in love.
      </p>
    </div>
  );
};

export default HeroHeader;
