import React from "react";
import FAQItem from "./FAQItem";
const FAQ = () => {
  const data = [
    {
      title: "How can I download the app",
      desc: "If you are using an android device, go to play store. If you are using an ios device go to app world and then search for “IFE” . The application will pop up and then you can download.",
    },
    {
      title: "Do you have verified users",
      desc: "Yes, we prioritize user safety and have verified profiles.",
    },
    {
      title: "How do I join the waitlist",
      desc: " To join the waitlist, visit our official website or download the app and follow the prompts. ",
    },
    {
      title: "Is it only accessible to people in Africa?",
      desc: "While initially focused on Africa, we aim for global accessibility. ",
    },
    {
      title: "Will there be subscription?",
      desc: "Subscription details are available on our website, providing premium features for an enhanced experience. Feel free to explore and enjoy Ife Dating!",
    },
  ];
  return (
    <div className="res-cont">
      <h1 className="heading-text text-center">Frequently Asked Questions</h1>
      <p className="text-center py-4 font-medium text-md leading-8 ">
        Here are a few questions we get on a regular day. <br />
        If you can’t find an answer, send us an email.
      </p>
      <div className="w-full md:px-28 px-14  md:flex justify-between items-center">
        {/* Description */}
        <div className="w-full">
          {data.map((item, index) => (
            <FAQItem item={item} key={index} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
