import React from "react";
import { approach } from "../../constants/import";

const Approach = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-24 res-cont">
      {/* Description */}
      <div className="md:mb-0 mb-6">
        <h1 className="heading-text">Our Approach</h1>
        <h1 className="font-semibold md:text-[28px] text-lg my-4 md:leading-9">
          Looking for that first date with your significant other?
        </h1>
        <p className="text-sm leading-7">
          We get it - you're more than just a cute picture. Our platform helps
          you showcase your unique personality so you stand out from the crowd.
          It’s a great way to attract matches who are more likely to be highly
          compatible with you.
        </p>
      </div>
      {/* Image */}
      <div className="w-full flex md:justify-end md:items-end justify-center items-center">
        <div>
          <img src={approach} className="img-cont" alt="Our Approach" />
        </div>
      </div>
    </div>
  );
};

export default Approach;
