import React, { useEffect, useState } from "react";
import { NavBar } from "../components";
import {
  Approach,
  Community,
  FAQ,
  Gallery,
  Hero,
  HeroHeader,
  WaitList,
  WorkShop,
} from "../components/Home";
import { Footer } from "../components/Layouts";

const Home = () => {
  return (
    <div>
      <NavBar />

      {/* Hero Header */}
      <HeroHeader />
      {/* Hero */}
      <Hero />
      {/* Our Approach */}
      <Approach />
      {/* Workshop */}
      <WorkShop />
      {/* Be part of out Community */}
      <Community />
      {/* Gallery */}
      <Gallery />
      {/* FAQ */}
      <FAQ />
      {/* WaitList */}
      <WaitList />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
