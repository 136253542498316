import React from "react";
import { own1, own2, own3 } from "../../constants/import";

const Gallery = () => {
  const data = [own3, own2, own1];

  return (
    <div className="res-cont flex flex-wrap justify-around items-center">
      {data.map((item, index) => (
        <img key={index} src={item} className="img-cont my-4" />
      ))}
    </div>
  );
};

export default Gallery;
