import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Analytics } from "@vercel/analytics/react";
import "react-toastify/dist/ReactToastify.css";
import { Home, JoinWaitList, WaitListings } from "./pages";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/waitlist" exact element={<JoinWaitList />} />
          <Route path="/waitlistings" exact element={<WaitListings />} />
        </Routes>
      </Router>
      <Analytics />
      <ToastContainer />
    </>
  );
};

export default App;
