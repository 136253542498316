import React from "react";
import { logo } from "../../constants/import";
import { Link } from "react-router-dom";

const Community = () => {
  return (
    <div className="w-full  flex flex-col items-center justify-center py-10">
      <h1 className="heading-text text-center">
        Be a part of the Ife community
      </h1>
      <div className="flex items-center py-5">
        <h1 className="text-3xl font-bold mr-2">Find your</h1>
        <div className="border border-primary rounded-full p-2">
          <img src={logo} className="w-[25px] h-[25px]" />
        </div>
      </div>
      <p className=" md:w-[450px] text-center py-4 font-medium text-md leading-8 md:px-1 px-5">
        {" "}
        We are looking for people who want to make dating effective not
        addictive. When it comes to matching and connecting, we've taken it a
        step further by introducing voice intros that make the experience feel
        authentic and natural, almost like it would in real life.
      </p>
      <Link className="primary-btn my-3" to={"/waitlist"}>
        Join Waitlist
      </Link>
    </div>
  );
};

export default Community;
