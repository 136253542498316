import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { LiaTimesSolid } from "react-icons/lia";
import { FiHome } from "react-icons/fi";
import { CiGlobe } from "react-icons/ci";
import { logo } from "../constants/import";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);
  const data = [
    { title: "Home", path: "/" },
    // { title: "About Us", path: "/waitlist" },
  ];
  const mobileData = [
    { title: "Home", path: "/", Icon: FiHome },
    { title: "Join Waitlist", path: "/waitlist", Icon: CiGlobe },
  ];
  return (
    <>
      {/* Desktop Size */}
      <div className="w-full px-5 md:px-28 py-4 flex items-center justify-between ">
        {/* Logo */}
        <Link to={"/"}>
          <img
            src={logo}
            className=" md:w-[25px] md:h-[40px] w-[20px] h-[30px]"
          />
        </Link>
        {/* Menu Items */}
        <div className="hidden md:block">
          {data.map((item, index) => (
            <Link
              key={index}
              className="text-xs font-medium px-8"
              to={item.path}
            >
              {item.title}
            </Link>
          ))}
        </div>
        {/* Button */}
        <Link to={"/waitlist"} className="primary-btn md:block hidden">
          Join Waitlist
        </Link>
        <div className="md:hidden" onClick={handleClick}>
          {!nav ? <GiHamburgerMenu size={20} /> : <LiaTimesSolid size={20} />}
        </div>
      </div>
      {/* Mobile Navbar */}
      <ul
        className={!nav ? "hidden" : "absolute top-12 left-0 bg-white w-full"}
      >
        <div className="py-5 px-5">
          {mobileData.map((item, index) => (
            <li
              key={index}
              className="flex items-center w-full text-black my-2 py-2 border-b border-b-gray-200"
            >
              <div className="bg-[#FFF4F4] rounded-full p-2 mr-3">
                <item.Icon size={15} className="text-primary" />
              </div>
              <Link
                onClick={handleClose}
                to={item.path}
                smooth={true}
                duration={500}
                className="text-xs"
              >
                {item.title}
              </Link>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};

export default NavBar;
