import React from "react";
import { logo } from "../../constants/import";
import { Link } from "react-router-dom";
import { socialData } from "../../constants/data";
const WaitList = () => {
  return (
    <div className="gradient-bg res-cont doubled-grid py-10">
      {/* col One */}
      <div className="mb-8">
        {/* Logo */}
        <img src={logo} className="w-[30px] h-[40px] m my-4" />
        {/* Desc */}
        <p className="basic-info leading-6">
          Meet your forever person today, because there's someone out there
          searching for you and we are here to help you find each other and fall
          in love.
        </p>
        {/* Social Links */}
        <div className="flex my-4">
          {socialData.map((item, index) => (
            <a
              target="_blank"
              href={item.link}
              key={index}
              className={`${
                index === 0 ? "" : ""
              } mr-3 bg-black rounded-full p-2`}
            >
              <item.Icon size={18} color="white" />
            </a>
          ))}
        </div>
      </div>
      {/* Col Two */}
      <div className="mb-8">
        <h1 className="heading-text mb-4">Join Our Waitlist...</h1>
        <p className="basic-info mb-8">
          We invite you to join our waiting list so we can experience Ife in a
          different light together... <br />
          We are looking for people who want to make dating effective not
          addictive.
        </p>
        <Link className="primary-btn" to={"/waitlist"}>
          Join Waitlist
        </Link>
      </div>
    </div>
  );
};

export default WaitList;
